
export const Contact = ({divStyle}) => {
    return (
        <div id='contactDiv' className='contactDiv' style={{paddingBottom: "100px", ...divStyle}}>
            <p className='title aboutTitle'>Kontakt</p>
            <p className="contactText">Falls sie buchen wollen, kontaktieren sie uns gerne per Mail</p>
            <p className="contactText contactLink" onClick={() => {
                window.open('mailto:info@ferienhaus-hitra.com', '_self');
            }}>info@ferienhaus-hitra.com</p>
            <img className='' src='/media/LogoFischercamp.jpg' alt='Loading...' width={"130px"} style={{marginTop: 15}} />
        </div>
    )
}